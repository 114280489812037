.album-artwork {
    width: 85%;
    max-width: 350px;
    height: auto;
    margin-top: 10px;
    margin-bottom: 5px;
    border: 1px solid black;

}

.player-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid blue; */
}

.play-button-container {
    padding-bottom: 10px;
}

.current-track-info {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align text to the start (left) */
    margin-left: 15px;
    margin-top: 3px

}

.player-header-content {
    display: flex;
    align-items: center;
    text-align: left;
    width: 85%;
    max-width: 350px;
    text-overflow: hidden; /* Add ellipsis for overflow text */
    margin-top: 4px;

}
