@font-face {
  font-family: 'Alcala';
  src: url('./fonts/Alcala.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Alcala-Italic';
  src: url('./fonts/Alcala-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Dina';
  src: url('./fonts/DinaRemasterCollection.ttc') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GogaTest';
  src: url('./fonts/GogaTest-Hairline.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'GogaTest';
  src: url('./fonts/GogaTest-Thin.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'GogaTest';
  src: url('./fonts/GogaTest-Extralight.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GogaTest';
  src: url('./fonts/GogaTest-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GogaTest';
  src: url('./fonts/GogaTest-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GogaTest';
  src: url('./fonts/GogaTest-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GogaTest';
  src: url('./fonts/GogaTest-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'GogaTest';
  src: url('./fonts/GogaTest-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'GogaTest';
  src: url('./fonts/GogaTest-Extrabold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'GogaTest';
  src: url('./fonts/GogaTest-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
