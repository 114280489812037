.navbar-account-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 0px 15px;

}

.logged-in-buttons {
  display: flex;
  gap: 15px;
  align-items: center;

}

.user-status-icon {
  height: 50px;
}

.account-button {
  max-width: min(550px, 55vw);
}

.account-button span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  text-align: left;
}

.account-button .email-text {
  display: inline;
}

.account-button .short-text {
  display: none;
}


@media (max-width: 470px) {
  .account-button .email-text {
    display: none;
  }
  
  .account-button .short-text {
    display: inline;
  }
}

.auth-buttons {
  display: flex;
  gap: 10px;
  margin-right: 10px;
}

@media (max-width: 830px) {
  .navbar-account-area .logged-in-buttons {
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;

  }
  
  .navbar-account-area {
    justify-content: flex-end;
    align-items: center;
  }
  
  .user-status-icon {
    display: none;
  }
}

@media (max-width: 500px) {
  .navbar-account-area .auth-buttons {
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;

  }
}

