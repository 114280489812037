.audio-shelf-container {
  display: flex;
  width: 100%;
  justify-content: center; /*centers the whole shelf horizontally */
  user-select: none;
  
}

.album-title {
  font-size: 27px;
  font-weight: 500;
  margin-top: 2px;
  margin-left: 0px;
}


/* 481 */
@media (max-width: 481px) {
  .album-title {
    font-size: 25px;
    margin-top: 4px;
  }
}

/* 429 */
@media (max-width: 429px) {
  .album-title {
    font-size: 23px;
    margin-top: 4px;
    margin-left: -2px;

  }
}

/* 405 */
@media (max-width: 405px) {
  .album-title {
    font-size: 22px;
    margin-top: 5px;

  }
}

/* 385 */
@media (max-width: 385px) {
  .album-title {
    font-size: 20px;
    margin-top: 7px;

  }
}

/* 372 */
@media (max-width: 372px) {
  .album-title {
    font-size: 19px;
    margin-top: 8px;

  }
}

/* 361 */
@media (max-width: 361px) {
  .album-title {
    font-size: 18px;
    margin-top: 8px;
  }
}

/* 348 */
@media (max-width: 348px) {
  .album-title {
    font-size: 17px;
    margin-top: 9px;
  }
}

/* 338 */
@media (max-width: 338px) {
  .album-title {
    font-size: 16px;
    margin-top: 10px;
  }
}

/* 329 */
@media (max-width: 329px) {
  .album-title {
    font-size: 15px;
    margin-top: 11px;
  }
}

/* 320 */
@media (max-width: 320px) {
  .album-title {
    font-size: 14px;
    margin-top: 12px;
  }
}

/* 305 */
@media (max-width: 305px) {
  .album-title {
    font-size: 13px;
    margin-top: 13px;
  }
}

/* 297 */
@media (max-width: 297px) {
  .album-title {
    font-size: 12px;
    margin-top: 14px;
    white-space: normal;
    text-align: left;
  }
}


.album-icon {
  border: 1px solid black;
  margin-left: 12px;
}


