.main-nav {
    display: flex;
    width: 100%;
    align-items: center;
    height: 110px;
}

.logo-area {
    display: flex;
    align-items: center;
    height: 100%;
    width: 65%; 
    max-width: 250px;

    min-width: 160px;
    justify-content: center !important;
}

.logo-area img {
    height: auto;
    width: 100%;
    max-height: 100%;
    justify-content: center !important;
    height: 100%;
    max-height: 110px;
    padding: 10px;
}

.account-area {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: slategray;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
}