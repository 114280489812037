.splash-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: orange; /* You can change this to match your theme */
  }
  
  .splash-logo {
    width: 20%;
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .spinner {
    border: 4px dotted whitesmoke;
    width: 30px;
    height: 30px;
    animation: spin 1.3s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  @media (max-width: 480px) {
    .splash-logo {
      width: 70%;
      max-width: 200px;
    }
  
    .spinner {
      width: 30px;
      height: 30px;
    }
  }
  