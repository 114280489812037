.about-shelf-container {
  display: flex;
  width: 100%;
  justify-content: center;
  user-select: none;
}

.about-title {
  font-size: 27px;
  font-weight: 500;
  margin-top: 2px;
}

.about-icon {
  margin-left: 10px;
}

@media (max-width: 481px) {
  .about-title {
    font-size: 25px;
    margin-top: 4px;
  }
}

@media (max-width: 429px) {
  .about-title {
    font-size: 23px;
    margin-top: 4px;
  }


}

@media (max-width: 405px) {
  .about-title {
    font-size: 22px;
    margin-top: 5px;
  }
}

@media (max-width: 385px) {
  .about-title {
    font-size: 20px;
    margin-top: 6px;
  }
}

@media (max-width: 372px) {
  .about-title {
    font-size: 19px;
    margin-top: 7px;
  }
}

@media (max-width: 361px) {
  .about-title {
    font-size: 18px;
    margin-top: 7px;
  }
}

@media (max-width: 348px) {
  .about-title {
    font-size: 17px;
    margin-top: 7px;
  }
}

@media (max-width: 338px) {
  .about-title {
    font-size: 16px;
    margin-top: 7px;
  }
}

@media (max-width: 329px) {
  .about-title {
    font-size: 15px;
    margin-top: 7px;
  }
}

@media (max-width: 320px) {
  .about-title {
    font-size: 14px;
    margin-top: 7px;
  }
}

@media (max-width: 305px) {
  .about-title {
    font-size: 13px;
    margin-top: 8px;
  }
}

@media (max-width: 297px) {
  .about-title {
    font-size: 12px;
    margin-top: 8px;
  }
}
